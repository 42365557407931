<template>
  <main class="profile">
    <PageHeader title="My Profile">
      <template slot="right">
        <router-link to="/settings" custom v-slot="{ navigate }">
          <button @click="navigate">
            <i class="fa-solid fa-sliders fa-lg"></i>
          </button>
        </router-link>
      </template>
    </PageHeader>
    <section class="user">
      <div class="logout" @click="logout">
        <i class="fa-solid fa-sign-out-alt fa-lg"></i>
      </div>
      <router-link to="/settings/account" custom v-slot="{ navigate }">
        <div class="edit" @click="navigate">
          <i class="fa-solid fa-pencil-alt fa-md"></i>
        </div>
      </router-link>
      <div class="avatar">
        <i class="fa-regular fa-user fa-xl"></i>
      </div>
      <div class="info">
        <div class="name">
          {{ user.username }}
          <i
            class="mod fa-solid fa-screwdriver-wrench"
            v-if="user.moderator"
          ></i>
        </div>
        <div class="email">{{ user.email }}</div>
      </div>
    </section>
    <h2>Your Connections</h2>
    <section class="platforms">
      <div
        class="platform"
        :style="{ 'background-color' : platData(plat.provider).color }"
        v-for="plat in user.platforms"
        :key="plat.provider"
      >
        <i :class="platData(plat.provider).icon"></i>
        <div class="name">
          {{ plat.name }}
        </div>
      </div>
      <div class="add">
        <router-link to="/settings/connections" custom v-slot="{ navigate }">
          <button @click="navigate">
            Manage connections in settings
          </button>
        </router-link>
      </div>
    </section>
  </main>
</template>

<script>
export default {
  data: () => ({
    loading: true,
  }),
  computed: {
    user() {
      return this.$store.state.user;
    },
    userLoading() {
      return this.$store.state.userLoading;
    }
  },
  created() {
    if (!this.$store.state.userLoading && !this.$store.state.user) this.$router.push("/");
  },
  methods: {
    platData(platform) {
      return this.$platforms.find(plat => plat.name === platform);
    },
    logout() {
      if (confirm("Are you sure you want to log out?")) {
        this.$store.dispatch('logout');
        this.$router.push("/");
      }
    }
  },
  watch: {
    userLoading(val) {
      if (val) return;
      if (!this.user) this.$back();
    }
  }
};
</script>